import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Kipping Pullups & HS Walks`}</p>
    <p>{`3 rounds for time (15MIN Cap)\\
50 Ft Handstand Walk\\
18 Chest-To-Bar Pull-ups\\
50 Ft Double DB Front Rack Lunge Walk (50lbs./35lbs)`}</p>
    <p><em parentName="p">{`Scaled: `}</em></p>
    <p>{`3 rounds for time (15MIN Cap)\\
50 Ft Bear Crawl\\
18 Jumping Pull-ups\\
50 Ft Double DB Front Rack Lunge Walk (35lbs./20lbs)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will be closed Sunday for Easter.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      